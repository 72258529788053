import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './account.css';
import TicketItems from './TicketItems';

export default class App extends Component {

  constructor(props){
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.baseUrl = process.env.REACT_APP_API_URL;
    const urlParams = new URLSearchParams(window.location.search);
    this.passType = urlParams.get('type'); 
    this.vehicleNoParam = urlParams.get('vehicle_no');
    this.initialState =  {
      fields: {
        searchInput: this.vehicleNoParam ? this.vehicleNoParam : ''
      },
      arenaId: urlParams.get('id'),
      errors: {},
      selection: 'license',
      ticketData: []
    }
    this.resetState = {
      booked: true,
      errors: {},
      fields: {searchInput: '', email: '', cardholder_name: ''},
      loading: false,
      ticketData: [],
      payableAmount: 0
    }
    this.state = this.initialState;
  }

  componentDidMount(){
    const defer = () =>  {
      if (window.Stripe) {
          this.initForm(this);
      } else {
          setTimeout(function() { defer ()  }, 50);
      }
    }
    this.refreshAfterSleep();
    defer();
    this.getTickets();
  }

  refreshAfterSleep(){
		var SAMPLE_RATE = 5000; 
		var lastSample = Date.now();
	  
		function refresh() {
		  if (Date.now() - lastSample >= SAMPLE_RATE * 2) {
			window.location.reload();
		  }
		  lastSample = Date.now();
		  setTimeout(refresh, SAMPLE_RATE);
		}
		refresh();
	}

  manageBodyBackground(isShow){
    if(isShow){
      document.querySelector('#root').classList.add('dark');
    }else{
      document.querySelector('#root').classList.remove('dark');
    }
  }

  onlyEmail(name){
    let fields = this.state.fields;
    if(!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(fields[name])){
      return false;
    }
    return true;
  }

  manageSelectedTickets(id){
    let ticketData = JSON.parse(JSON.stringify(this.state.ticketData));
    for(let i=0; i<ticketData.length; i++){
      if(ticketData[i].id === id){
        ticketData[i].selected = !ticketData[i].selected; 
        break;
      }
    }
    let payableAmount = ticketData.filter(item => item.selected).reduce((a, b) =>  a+ b['rental_fee'], 0);
    let fee = payableAmount ? (payableAmount * 0.029) + 30 : 0;
    let grandTotal = payableAmount ? payableAmount + fee : 0;
    this.setState({
      ticketData,
      payableAmount,
      fee,
      grandTotal
    });
  }

  getTickets(){
    if(!this.state.arenaId) return;
    this.manageBodyBackground(true);
    this.setState({loading: true});
    const url = `/parking/monthly/detail/hash?pass_hash=`;
    fetch(`${this.baseUrl}${url}${this.state.arenaId}`)
        .then(response => response.json())
        .then(resp => {
          if(resp.data && resp.status === "SUCCESS"){
            this.manageBodyBackground(false);
            let respData = Array.isArray(resp.data) ? resp.data : Object.keys(resp.data).length === 0 ? [] : [resp.data];
            respData = respData.filter(item => item.status != 'paid');
            respData.forEach((item, index) => {
              respData[index].selected = true;
            })
            let payableAmount = respData.filter(item => item.selected).reduce((a, b) =>  a+ b['rental_fee'], 0);
            let fee = payableAmount ? (payableAmount * 0.029) + 30 : 0;
            let grandTotal = payableAmount ? payableAmount + fee : 0;
            this.setState({
              loading: false,
              ticketData: respData,
              payableAmount,
              fee,
              grandTotal
            }, () => { if(!this.state.ticketData.length) toast.error('No passes found')});
          }else{
            this.manageBodyBackground(false);
            this.setState({loading: false});
            let errMsg = resp.error && resp.error.reason ? resp.error.reason : 'Something went wrong'; 
            toast.error(errMsg);
          }
        })
        .catch((error) => {
          this.manageBodyBackground(false);
          this.setState({loading: false});
          toast.error('Something went wrong');
        });
  }

  handleChange(e) {
    let fields = {...this.state.fields};
    fields[e.target.name] = e.target.value;
    this.setState({
        fields
      });
  }

  nonSpecialCharacter(name){
      let fields = this.state.fields;
      return !/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(fields[name]);
  }

  

  handleSubmit(e){
    e.preventDefault();
    const submitBtn = document.getElementById("pay-submit");
    submitBtn.disabled = true;
    const request = {
      ticket_ids: this.state.ticketData.filter(x => x.selected).map(x => x.id)
    }
    let formatting = [];
    if(request.ticket_ids && request.ticket_ids.length){
      request.ticket_ids.forEach(item => {
        formatting.push({
          pass_id: item ? item.toString() : '',
          payment_type: this.passType
        })
      })
    }
    fetch(`${this.baseUrl}/payment/parking/monthly/cancel`, {
      method: 'post',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({
        pass_list: formatting
      })
     })
      .then(response => response.json())
      .then(resp => {
        if(resp.data && resp.status === "SUCCESS"){
          window.scrollTo(0,0);
          this.setState({...this.resetState, bookingID: resp.data.booking_id}, () => {
            submitBtn.disabled = false;
          });
        }else{
          let errMsg = resp.error && resp.error.reason ? resp.error.reason : 'Something went wrong'; 
          this.manageBodyBackground(false);
          this.setState({loading : false});
          toast.error(errMsg, { autoClose: errMsg.length > 20 ? 10000 : 2000 });
          submitBtn.disabled = false;
        }
      })
      .catch((error) => {
        this.manageBodyBackground(false);
        this.setState({loading : false});
        toast.error('Something went wrong');
        submitBtn.disabled = false;
      });  
  }

  handleFocus(name){
    document.querySelector(`input[name="${name}"]`).focus();
    return false;
  }

  handleClose(){
    this.setState({booked: false, bookedID: null }, () => {
      this.manageBodyBackground(false);
    })
  }

  handleSelection(e){
    this.setState({
      selection: e.target.value
    });
  }


  render() {
    return (
      <div style={{position: 'relative'}}>
        {this.state.loading && <div style={{position: 'absolute', width: '100%', height: '100%', zIndex: 10}}></div>}
        {this.state.loading && <div className="loader">Loading...</div>}
        <div style={{display: this.state.booked ? 'block' : 'none'}} className="success-message">
          {/* <span onClick={this.handleClose.bind(this)} id="sm-close">&times;</span> */}
          <svg viewBox="0 0 76 76" className="success-message__icon icon-checkmark">
            <circle cx="38" cy="38" r="36"></circle>
            <path fill="none" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M17.7,40.9l10.9,10.9l28.7-28.7" style={{strokeDashoffset: 0, strokeDasharray: 56.0029}}></path>
          </svg>
          <h3 className="success-message__title">Unsubscribed Successfully</h3>
          <p style={{position: 'relative', top: '28px', fontSize: '12px', color: '#888'}}>(You can close this tab)</p>
          
        </div>
        
        <form id="mainForm" onSubmit={this.handleSubmit} style={{pointerEvents: this.state.booked || this.state.seeMore ? 'none' : 'auto', visibility: this.state.booked ? 'hidden' : 'visible'}} action="#" method="POST">
          <input type="hidden" name="token" />
          <div style={{textAlign: 'center', paddingBottom: '20px'}}>
            <img style={{height: 85}} src="https://portal.parkquility.com/public/pq_logo.png" />
          </div>
          <div>
            <h2 style={{textAlign: 'center', fontSize: '22px'}}>Cancel Monthly Subscription</h2>
          </div>
          <div style={{marginTop: '35px'}} className="ticket-wrapper">
            
            {this.state.ticketData.length > 0 && 
              this.state.ticketData.map((item, index) => <TicketItems key={'t' + index} manageSelectedTickets={this.manageSelectedTickets.bind(this)} length={this.state.ticketData.length} data={item} />)
            }
            
            <div className="amount-block" style={{visibility: this.state.ticketData.length ? 'visible' : 'hidden', opacity: this.state.ticketData.length ? 1 : 0}}>
              
              <button  id="pay-submit" type="submit">Confirm Cancellation</button>
              <div style={{clear: 'both'}} />
            </div>
          </div>
          <ToastContainer autoClose={2000} closeButton={true} style={{fontSize: 14}}/>
        </form>
      </div>      
    )
  }
}

import React, { Component } from 'react';
import gpsIcon from "./img/gps.png";

export default class TicketItems extends Component {
    render() {
        const data = this.props.data;
        if(!data) return null;
         

    return(
        <div key={data.id} className="ticket-item">
                {this.props.length > 1 && <label class="check-wrapper"> 
                    <input onClick={() => this.props.manageSelectedTickets(data.id)} type="checkbox" checked={data.selected} />
                    <span class="checkmark"></span>
                </label>}
                <div style={{textAlign: 'left', paddingBottom: '10px', paddingRight: '25px'}} className="location">
                    <img style={{height: 28}} src={gpsIcon} /> 
                    <span style={{lineHeight: '26px'}}>{data.address}</span>
                </div>
                <ul className="pay-timings">
                    <li>
                        <span className="list-initial">Account Name</span>
                        <span>{data.account_name}</span>
                    </li>
                    <li>
                        <span className="list-initial">First Name:</span>
                        <span>{data.first_name}</span>
                    </li>
                    <li>
                        <span className="list-initial">Last Name</span>
                        <span>{data.last_name}</span>
                    </li>
                    <li>
                        <span className="list-initial">Pass Type</span>
                        <span>{data.pass_type}</span>
                    </li>
                    <li>
                        <span className="list-initial">Vehicle No.</span>
                        <span>{data.vehicle_no}</span>
                    </li>
                    <li>
                        <span className="list-initial">Amount</span>
                        <span>{data.rental_fee ? '$' + (data.rental_fee/100).toFixed(2) + '/per month' : ''}</span>
                    </li>
                    <li>
                        <span className="list-initial">Validity Start</span>
                        <span>{data.validity_start_time}</span>
                    </li>
                    <li>
                        <span className="list-initial">Validity End</span>
                        <span>{data.validity_end_time}</span>
                    </li>
                </ul>
            </div>  
    )
}

}
